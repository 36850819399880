/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import { BrowserView, MobileView } from "react-device-detect";
import { BiPhotoAlbum, BiHome } from "react-icons/bi";
import { GiFamilyTree, GiKnifeFork } from "react-icons/gi";
// import { CiWarning } from "react-icons/ci";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import aws_exports from "./aws-exports";
import Header from "./components/Header";
import Home from "./components/Home";
import FamilyTree from "./components/FamilyTree";
import Recipes from "./components/Recipes";
// import Gallery from "./components/Gallery";
import Profile from "./components/Profile";
// import Settings from "./components/Settings";
import Wip from "./components/Wip";
import "react-dropzone-uploader/dist/styles.css";
// eslint-disable-next-line import/no-unresolved
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(aws_exports);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const bodyStyle = {
    // minWidth: "1000px",
};

const headerStyle = {
    display: "inline-flex",
    width: "100%",
    boxShadow: "0px 0px 9px 3px rgba(41,41,41,.25)",
    height: "2.75em",
    minWidth: "1075px",
};

const navStyle = {
    // margin: "0.05em",
    marginLeft: "0.1em",
    width: "40%",
    float: "left",
};

const buttonStyle = {
    margin: "0.2em",
    alignItems: "center",
    display: "flex",
};

const buttonIconStyle = {
    marginRight: "0.2em",
};

// const footerStyle = {
//     position: "absolute",
//     textAlign: "center",
//     // width: "100vw",
//     boxShadow: "0px 0px 9px 3px rgba(41,41,41,.25)",
//     margin: "0 30% 1em 30%",
//     background: "#ff9999",
//     left: 0,
//     bottom: 0,
//     right: 0,
//     borderRadius: "2em",
//     zIndex: 999,
// };

const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === "/";
};

function App({ signOut, user }) {
    // const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    let chosenTester = false;
    if (user.attributes.email === "david.fedorovsky@gmail.com") chosenTester = true;
    return (
        <BrowserRouter>
            <header style={headerStyle}>
                <ButtonToolbar style={navStyle}>
                    <LinkContainer isActive={checkActive} to="/" style={buttonStyle}>
                        <Button variant="outline-primary">
                            <BiHome style={buttonIconStyle} /> Home
                        </Button>
                    </LinkContainer>
                    <LinkContainer to="/gallery" style={buttonStyle}>
                        <Button variant="outline-secondary">
                            <BiPhotoAlbum style={buttonIconStyle} /> Gallery
                        </Button>
                    </LinkContainer>
                    <LinkContainer to="/recipes" style={buttonStyle}>
                        <Button variant="outline-secondary">
                            <GiKnifeFork style={buttonIconStyle} /> Recipes
                        </Button>
                    </LinkContainer>
                    <LinkContainer to="/familytree" style={buttonStyle}>
                        <Button variant="outline-primary">
                            <GiFamilyTree style={buttonIconStyle} /> Family Tree
                        </Button>
                    </LinkContainer>
                </ButtonToolbar>
                <Header signOut={signOut} user={user} />
            </header>
            <div style={bodyStyle}>
                <Routes>
                    <Route path="/" exact element={Home({ user })} />
                    <Route path="/familytree" Component={FamilyTree} />
                    <Route path="/recipes" element={chosenTester ? Recipes() : <Wip />} />
                    <Route path="/gallery" Component={Wip} />
                    <Route path="/profile" element={Profile({ user })} />
                    <Route path="/settings" Component={Wip} />
                    {/* <Route path="/settings" element={Settings()} /> */}
                </Routes>
                {/* <MobileView>
                    <h1>This is rendered only on mobile</h1>
                </MobileView> */}
                {/* <BrowserView>
                    <div style={footerStyle}>
                        <CiWarning /> Mobile view is not optimized, please use a computer/laptop/tablet
                    </div>
                </BrowserView> */}
            </div>
        </BrowserRouter>
    );
}

export default withAuthenticator(App);
