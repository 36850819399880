import { React, Component } from "react";
import BarLoader from "react-spinners/BarLoader";

const loader = <BarLoader height={4} width={"100vw"} color={"#00BFFF"} />;

class FamilyTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: undefined,
            gedDataLocation: undefined,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        /*

        Read gedcom file and replace all FILE lines that don't end in .ged with:

        window.location.href.replace("/familytree", "/familytreedata")/photos/name.jpg

        */

        // console.log("in componentDidMount");

        const originalGed = "/familytreedata/fedorovsky_12_26.ged";
        const location = window.location.href.replace("/familytree", "/familytreedata");

        fetch(originalGed)
            .then((response) => response.text())
            .then((textContent) => {
                // console.log("in fetch", textContent);
                const gedData = textContent.split("\n");
                let j = 0;
                gedData.forEach((line, i) => {
                    // console.log("in forEach", i, line);
                    const splitLine = line.split(" ");
                    if (splitLine[1] === "FILE" && !splitLine[splitLine.length - 1].includes(".ged")) {
                        // console.log("in condition", splitLine[1]);
                        // replace the file location with the local reactjs location
                        splitLine[splitLine.length - 1] = `${location}/photos/${splitLine[splitLine.length - 1].split("\\").pop()}`;
                        // replace the line in the list
                        gedData[i] = splitLine.join(" ");
                    }
                    j += 1;
                    // console.log("counters : ", i, j, gedData.length);
                    if (j === gedData.length - 1) {
                        // console.log("hit criteria, setting loader to false");
                        const newFile = new File([gedData.join("\n")], { type: "text/plain" });
                        const newGedDataLocation = URL.createObjectURL(newFile);
                        this.setState({ loading: false, gedDataLocation: newGedDataLocation });
                    }
                });
            });
    }

    render() {
        if (this.state.loading) return loader;

        // ged file is set in state as gedDataLocation after componentDidMount runs
        const drawIoEmbed = `<iframe frameborder="0" style="width:100%;height:85vh;" src="familyviewer/index.html#/view?url=${this.state.gedDataLocation}&handleCors=false&embedded=true"></iframe>`;

        return (
            <div className="App">
                <div dangerouslySetInnerHTML={{ __html: drawIoEmbed }}></div>
            </div>
        );
    }
}

export default FamilyTree;
